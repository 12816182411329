import httpClient from "@/client/httpClient";

export default class RegionService {
	static API_ENDPOINT = "region";

	static async getAll() {
		const response = await httpClient().get(this.API_ENDPOINT);
		return response.data;
	}
}
