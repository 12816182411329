import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/organizations",
        name: "Organizations",
        component: () => import("@/views/OrganizationsList.vue")
    },
    {
        path: "/organization/:id",
        name: "Organization Profile",
        component: () => import("@/views/OrganizationProfile.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
