<template>
    <canvas width="165" height="165" ref="canvas" />
</template>

<script>
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export const CHART_COLORS = {
	red: 'rgb(255, 99, 132)',
	orange: 'rgb(255, 159, 64)',
	yellow: 'rgb(255, 205, 86)',
	green: 'rgb(75, 192, 192)',
	blue: 'rgb(54, 162, 235)',
	purple: 'rgb(153, 102, 255)',
	grey: 'rgb(201, 203, 207)'
};

export default {
    name: "PieChart",
    data() {
        return {};
    },
    mounted() {
        new Chart(this.$refs.canvas, {
            type: "pie",
            data: {
                labels: ["one", "two", "three"],
                datasets: [
                    {
                        label: "kek",
                        data: [30, 30, 40],
                        backgroundColor: Object.values(CHART_COLORS)
                    }
                ]
            },
            options: {
                responsive: false,
                plugins: {
                    legend: {
                    	display: false,
                    },
                    title: {
                        display: true,
                        text: "Name",
						position: "bottom"
                    }
                }
            }
        });
    }
};
</script>

<style scoped></style>
