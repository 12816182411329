<template>
    <header>
        <router-link to="/" class="logo">
            <img src="../assets/img/logo.svg" alt="Logo" />
        </router-link>

        <nav>
            <router-link to="/" exact>Главная</router-link>
            <router-link to="/organizations">Список организаций</router-link>
        </nav>
    </header>
</template>

<script>
export default {
    name: "Header"
};
</script>

<style scoped lang="scss">
header {
    height: 80px;
    box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    background: #0284c7;
    padding: 0 20px;

    .logo {
        display: flex;
        align-items: center;
    }

    nav {
        margin-left: 36px;

        a {
            margin-right: 40px;
            color: #f9fafb;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            padding: 8px 0;

            &.router-link-active {
                border-bottom: 1px solid #d1d5db;
            }
        }
    }
}
</style>
