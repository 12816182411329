<template>
    <div class="home">
        <Header class="header" />

        <div class="filters">
            <!-- <v-autocomplete label="Регион" outlined color="primary" hide-details />
            <v-autocomplete label="Уровень" outlined color="primary" hide-details class="mt-4" /> -->

            <!-- <div class="d-flex align-center justify-space-between mt-8">
                <span>Атомный</span>
                <v-switch v-model="filters.isAtomCity" hide-details color="primary" />
            </div> -->
            <div class="d-flex mt-4 line-filters">
                <v-autocomplete
                    v-model="types"
                    filled
                    hide-details
                    outlined
                    multiple
                    label="Тип учреждения"
                    clearable
                    :items="orgTypeOptions"
                    style="max-width: 250px;"
                />
            </div>
            <div class="d-flex align-center justify-space-between mt-8">
                <span>Аналитика по регионам</span>
                <v-switch v-model="filters.analytics" hide-details color="primary" />
            </div>

            <!-- <v-btn color="primary" class="mt-16" style="width: 100%">Применить</v-btn> -->
        </div>

        <MapComponent :filter="filters" :type="types" class="map" />
    </div>
</template>

<script>
import MapComponent from "@/components/MapComponent";
import Header from "@/components/Header";
import PieChart from "@/components/PieChart";
import { OrganizationTypesService } from "@/services/OrganizationTypesService";

export default {
    components: {
        PieChart,
        Header,
        MapComponent
    },
    name: "Home",
    data() {
        return {
            filters: {},
            orgTypeOptions: [],
            types: []
        };
    },
    mounted() {
        OrganizationTypesService.getAllTypes().then((types) => {
            this.orgTypeOptions = types.map((t) => ({
                value: t.id,
                text: t.description
            }));
        });
    }
};
</script>
<style scoped lang="scss">
.header {
    z-index: 1;
    position: relative;
}

.filters {
    position: absolute;
    z-index: 1;
    background: white;
    top: 50%;
    transform: translateY(-50%);
    left: 1%;
    box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 24px 26px;
}

.charts {
    position: absolute;
    z-index: 1;
    background: white;
    top: 50%;
    transform: translateY(-50%);
    right: 1%;
    box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 24px 26px;
}

.map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}

.home {
    height: 100%;
}
.line-filters {
    .v-text-field--outlined:not(:last-child) fieldset {
        border-right: none;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .v-text-field--outlined:not(:first-child) fieldset {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}
</style>

<style>
.home .v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
</style>
