import httpClient from "@/client/httpClient";

export class OrganizationTypesService {
	static API_ENDPOINT = "/outer/org"

	static async getAllTypes() {
		const response = await httpClient().get(`${this.API_ENDPOINT}/type`);

		return response.data;
	}
}
