<template>
    <l-map @click="visibleMarkers" style="height: 100%; wifth: 100%" :zoom="zoom" :center="center">
        <l-tile-layer :url="url"></l-tile-layer>
        <l-geo-json
            ref="myGeojson"
            :geojson="geojson"
            :options="options"
            :options-style="styleFunction"
        ></l-geo-json>
    </l-map>
</template>
<script>
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import { Icon } from "leaflet";
import RegionService from "@/services/RegionService";
import StatisticService from "@/services/StatisticService";
import { OrganizationService } from "@/services/OrganizationService";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
export default {
    components: {
        LMap,
        LTileLayer,
        LGeoJson
    },
    name: "HelloWorld",
    props: {
        filter: Object,
        type: Array
    },
    data() {
        return {
            url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
            zoom: 4,
            center: [65.7558, 80.6173],
            geojson: {
                features: []
            },
            options: {},
            maxCount: 0,
            markers: [],
            regions: [],
            statistics: []
        };
    },
    async mounted() {
        this.regions = await RegionService.getAll();
        this.statistics = await StatisticService.getAll();
        this.loadPoints();
        this.options = {
            onEachFeature: this.onEachFeature,
            limitMarkersCount: 1,
            limitMarkersCountGlobally: true
        };
    },
    methods: {
        async loadPoints() {
            const points = await OrganizationService.getAll({
                isAtomCity: false,
                isRaexOrg: false,
                countRow: 100000,
                skip: 1,
                types: this.type
            });
            this.markers = points.organizationDto
                .map((p) => {
                    if (p.organization.lat) {
                        return {
                            type: "Feature",
                            properties: {
                                Name: "Woodlands Medical Centre",
                                Status: "Operational",
                                organization: p.organization,
                                distance: 0
                            },
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    Number(p.organization.lon),
                                    Number(p.organization.lat)
                                ]
                                //
                            }
                        };
                    }
                })
                .filter((a) => a);
        },
        getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
            var R = 6371; // Radius of the earth in km
            var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
            var dLon = this.deg2rad(lon2 - lon1);
            var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.deg2rad(lat1)) *
                    Math.cos(this.deg2rad(lat2)) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c; // Distance in km
            return d;
        },

        deg2rad(deg) {
            return deg * (Math.PI / 180);
        },
        distance(lat, lng, lat2, lng2) {
            return Math.sqrt(Math.pow(lat2 - lat, 2) - Math.pow(lng2 - lng, 2));
        },
        visibleMarkers(e) {
            this.markers = this.markers.map((m) => {
                m.properties.distance = this.getDistanceFromLatLonInKm(
                    e.latlng.lat,
                    e.latlng.lng,
                    m.geometry.coordinates[1],
                    m.geometry.coordinates[0]
                );
                return m;
            });
            let distances = [];

            let will_markers = this.markers;
            will_markers.sort((a, b) => {
                return a.properties.distance - b.properties.distance;
            });
            this.geojson.features = this.geojson.features.filter((f) => {
                return f.geometry.type != "Point";
            });
            this.geojson.features = this.geojson.features.concat(will_markers.slice(0, 100));
        },
        whenClicked(e) {
            if (e.target.feature.geometry.type == "Point") {
                window
                    .open(
                        "https://atom.kovalev.team/organization/" +
                            e.target.feature.properties.organization.id,
                        "_blank"
                    )
                    .focus();
            }
            this.$emit("regionClick", e.target.feature.properties);
        },
        onEachFeature(feature, layer) {
            layer.on({
                click: this.whenClicked
            });
            let types = "";

            if (feature.geometry.type != "Point") {
                try {
                    types = Object.entries(feature.properties.types)
                        .map((type) => type.join(": "))
                        .join("</br>");
                } catch (error) {
                    console.error(feature.properties);
                }
                layer.bindTooltip(
                    "<div>" + feature.properties.nameRu + "</br>" + types + "</div>",
                    {
                        permanent: false,
                        sticky: true
                    }
                );
            } else {
                layer.bindTooltip(
                    "<div>" +
                        "<a href='https://atom.kovalev.team/organization/" +
                        feature.properties.organization.id +
                        "'>" +
                        feature.properties.organization.name +
                        "</a></div>",
                    {
                        permanent: false,
                        sticky: true
                    }
                );
            }
        }
    },
    computed: {
        styleFunction() {
            return (obj) => {
                let fillOpacity = 0.1;
                if (obj.properties.types?.всего) {
                    fillOpacity = obj.properties.types.всего / this.maxAll;
                }
                return {
                    weight: 2,
                    color: "#1E3A8A",
                    opacity: 1,
                    fillOpacity: fillOpacity >= 0.9 ? 0.9 : fillOpacity
                };
            };
        }
    },
    watch: {
        type: {
            handler(v) {
                this.loadPoints();
            }
        },
        filter: {
            async handler(val, prev_val) {
                if (val.analytics) {
                    const response = await fetch(`https://atom.kovalev.team/jsons/geo.json`);
                    let json = await response.json();
                    json.features = json.features.map((feature) => {
                        let region = this.regions.find((r) => {
                            return r.nameEng == feature.properties.NAME_1;
                        });
                        feature.properties.nameRu = region?.nameRu;
                        feature.properties.atomId = region?.id;
                        feature.properties.types =
                            this.statistics["regionStatistics"][feature.properties.nameRu]?.types;
                        if (feature.properties.types) {
                            feature.properties.types["всего"] = Object.values(
                                this.statistics["regionStatistics"][feature.properties.nameRu].types
                            ).reduce((a, b) => a + b);
                        }
                        return feature;
                    });
                    this.maxAll =
                        Math.max.apply(
                            Math,
                            json.features.map((f) => f.properties.types?.всего).filter((r) => r)
                        ) - 10000;
                    this.geojson = json;
                } else {
                    this.geojson.features = this.geojson.features.filter((f) => {
                        return f.geometry.type == "Point";
                    });
                    this.geojson = this.geojson;
                }
            },
            deep: true
        }
    }
};
</script>