import httpClient from "@/client/httpClient";
import { constructUrlWithQueryParams } from "@/client/query-params-utils";

export class OrganizationService {
    static API_ENDPOINT = "/org";

    static async getAll(filters) {
        const url = constructUrlWithQueryParams(`${this.API_ENDPOINT}/find`, filters);

        const response = await httpClient().get(url);

        return response.data;
    }
}
